<template>
  <div
      class="app-content content"
      :class="[{'show-overlay': $store.state.app.shallShowOverlay}]"
  >
    <div class="content-overlay"/>
    <div class="header-navbar-shadow"/>
    <div class="content-wrapper">
      <div class="content-body">
        <transition name="zoom-fade" mode="out-in">
          <div class="card">
            <div class="m-1"></div>
            <div class="row">
              <div class="col-12">
                <ul role="tablist" class="nav nav-tabs">
                  <li role="presentation" class="nav-item">
                    <router-link :to="{ name: 'email-templates' }" class="nav-link">Шаблоны</router-link>
                  </li>
                  <li role="presentation" class="nav-item">
                    <router-link :to="{name: 'mailing-list'}" class="nav-link active">Рассылка</router-link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MailingList',
  data() {
    return {
      showModal: false,
      email: '',
      items: [],
    };
  },
  methods: {
    openModal() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
    addItem() {
      this.items.push({
        id: this.items.length + 1,
        email: this.email,
      });
      this.closeModal();
    },
    deleteItem(id) {
      this.items = this.items.filter((item) => item.id !== id);
    },
  },
};
</script>

<style scoped>

</style>
